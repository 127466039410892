"use client";

import ReactDOM from "react-dom";
import dynamic from "next/dynamic";
import { FormattedMessage } from "react-intl";

import { ViewBoxAppBar } from "@/widgets/app-bar/ui/app-bar";
import { ViewBoxAppBarTitle } from "@/widgets/app-bar/ui/app-bar-title";

const MediaTypeTabs = dynamic(
  () =>
    import("./media-type-tabs").then(({ MediaTypeTabs }) => ({
      default: MediaTypeTabs,
    })),
  {},
);

const Featured = dynamic(
  () =>
    import("@/widgets/featured/ui/featured").then(({ Featured }) => ({
      default: Featured,
    })),
  {},
);

function PreloadResources(): React.ReactNode {
  ReactDOM.preconnect("https://apitmdb.viewbox.fun");
  ReactDOM.preconnect("https://imagetmdb.viewbox.fun", { crossOrigin: "" });

  return;
}

export function MainPage() {
  return (
    <>
      <PreloadResources />
      <ViewBoxAppBar
        title={
          <ViewBoxAppBarTitle>
            <FormattedMessage id="main" />
          </ViewBoxAppBarTitle>
        }
      />

      <Featured />
      <MediaTypeTabs />
    </>
  );
}
